import { AxiosResponse } from "axios";
import { AnyAction } from "redux";
import AccountsAPI from "utils/api/AccountsAPI";

import { ACTION_STATUSES, TActionStatus } from "app/store/types";

import { TAccount } from "./types";

export const ACCOUNT_ACTIONS: { [key: string]: string } = {
  SET_ACCOUNT: "SET_ACCOUNT",
  GET_ACCOUNT: "GET_ACCOUNT",
  SET_ACCOUNT_STATUS: "SET_ACCOUNT_STATUS",
};

export type OrderActionTypes = {
  SET_ACCOUNT: AnyAction;
  SET_ACCOUNT_STATUS: AnyAction;
};

export const SetAccountData = (account: TAccount) => ({
  type: ACCOUNT_ACTIONS.SET_ACCOUNT,
  payload: account,
});

export const SetAccountStatus = (status: TActionStatus) => ({
  type: ACCOUNT_ACTIONS.SET_ACCOUNT_STATUS,
  payload: status,
});

type TAny = () => any;

export const getAccount: TAny = () => async (dispatch: any) => {
  await dispatch(SetAccountStatus(ACTION_STATUSES.PENDING));

  try {
    const account = (await AccountsAPI.getAccount()) as AxiosResponse;

    const accountData = await account.data;

    dispatch(SetAccountData(accountData));
    dispatch(SetAccountStatus(ACTION_STATUSES.LOADED));
  } catch (e) {
    console.warn(e);
    dispatch(SetAccountStatus(ACTION_STATUSES.FAILED));
  }
};
