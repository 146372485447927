import React, { FC, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { Typography, Spacing } from "@welldigital/components";
import clsx from "clsx";
import {
  Card,
  CardContent,
  Grid,
  Collapse,
  useMediaQuery,
  Box,
  Divider,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";

import ExpandMore from "@material-ui/icons/ExpandMore";
import { formatPrice } from "utils/formatters";
import { OrderProduct } from "app/store/reducer/order/types";
import { getProduct } from "app/store/reducer/order/selectors";
import { RootState } from "app/store/types";
import { DiscountBlock } from "components/Basket/DiscountBlock";
import { useStyles } from "components/Basket/styles";

const Basket: FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"), { noSsr: true });
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const product: OrderProduct = useSelector((state: RootState) => {
    return getProduct(state || {});
  });

  const handleCollapse = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const renderEmptyState = () => (
    <Typography
      variant={"subtitle2"}
      spacingAfter={0}
      classes={{ root: classes.detailsText }}
    >
      You must select a product.
    </Typography>
  );
  const renderProduct = (productPaylod: OrderProduct) => (
    <>
      <Box paddingBottom={2} className={classes.productSummary}>
        <Grid container wrap={"nowrap"}>
          <img
            className={classes.image}
            src={productPaylod?.image as string}
            alt={"product"}
          />

          <Grid item style={{ flex: 1 }}>
            <Grid container justify={"space-between"} wrap={"nowrap"}>
              <Typography className={classes.defaultText}>
                {productPaylod?.name} {productPaylod?.variant},{" "}
                {productPaylod?.packs[0].label}
              </Typography>
              <Typography style={{ paddingLeft: "10px", color: "#0C161F" }}>
                {formatPrice(productPaylod?.packs[0].basePrice ?? 0)}
              </Typography>
            </Grid>
            <Spacing spacing={1} />
            <Grid container justify={"space-between"} wrap={"nowrap"}>
              <Typography className={classes.blueText}>
                Delivered in discreet packaging
              </Typography>
              <Typography
                className={classes.blueText}
                style={{ paddingLeft: "10px" }}
              >
                Free
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <DiscountBlock />
      </Box>

      <Box marginTop={4}>
        <Divider classes={{ root: classes.dividerDark }} />
      </Box>

      <Box className={classes.totalWrapper}>
        <Grid container spacing={1} alignItems={"center"}>
          <Grid item xs={6}>
            <Typography
              variant={"h4"}
              spacingAfter={0}
              classes={{ root: classes.darkColor }}
              style={{ fontWeight: 700 }}
            >
              Total to pay
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              variant={"h2"}
              align={"right"}
              spacingAfter={0}
              classes={{
                root: clsx(classes.darkColor, classes.totalPrice),
              }}
            >
              {formatPrice(productPaylod?.packs[0].price ?? 0)}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );

  return (
    <Box>
      <Card classes={{ root: classes.card }}>
        <CardContent classes={{ root: classes.cardContent }}>
          <Collapse
            in={isDesktop ? true : isOpen}
            timeout={"auto"}
            collapsedHeight={"32px"}
          >
            <Box
              className={clsx(classes.titleWrapper, {
                [classes.hasAction]: !isDesktop,
              })}
              onClick={!isDesktop ? handleCollapse : undefined}
            >
              <Box display={"flex"} alignItems={"center"}>
                <ShoppingCartIcon className={classes.titleIcon} />
                <Typography
                  variant={"h3"}
                  classes={{ root: classes.darkColor }}
                >
                  Basket summary
                </Typography>
              </Box>
              {!isDesktop && (
                <ExpandMore
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: isOpen,
                  })}
                />
              )}
            </Box>
            {product && renderProduct(product)}
            {!product && renderEmptyState()}
          </Collapse>
        </CardContent>
      </Card>
    </Box>
  );
};

export default Basket;
