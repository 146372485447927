import React from "react";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import { Typography, Alert, Divider } from "@welldigital/components";
import { useStyles } from "pages/OnlineServicesOrderDetailsPage/styles";

interface Props {}

export const RenderPaymentIssue: React.FC<Props> = () => {
  const classes = useStyles();

  return (
    <>
      <Alert
        message={
          <>
            <Typography
              color={"inherit"}
              variant={"subtitle2"}
              spacingAfter={1}
            >
              Subscription couldn’t be confirmed
            </Typography>
            <Typography color={"inherit"} variant={"caption"}>
              We were unable to set up your subscription due to an error with
              your payment and you have not been charged. Check your payment
              details are correct before trying to pay again or use an
              alternative payment method.
            </Typography>
          </>
        }
        icon={CreditCardIcon}
        className={classes.warningAlert}
        spacingAfter={4}
      />
      <Divider />
    </>
  );
};

export default RenderPaymentIssue;
