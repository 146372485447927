import { OrderProduct, OrderCustomer } from "./types";

export const ORDER_ACTIONS: { [key: string]: string } = {
  SET_CUSTOMER: "SET_CUSTOMER",
  SET_PRODUCT: "SET_PRODUCT",
  SET_PAYMENT: "SET_PAYMENT",
  CLEANUP_ORDER_FLOW_STATE: "CLEANUP_ORDER_FLOW_STATE",
};

export type SetCustomerActionType = {
  type: string;
  payload: OrderCustomer;
};

export type SetProductActionType = {
  type: string;
  payload: OrderProduct;
};

export type OrderActionTypes = {
  SET_CUSTOMER: SetCustomerActionType;
};

export const SetCustomerAction = (customer: OrderCustomer) => ({
  type: ORDER_ACTIONS.SET_CUSTOMER,
  payload: customer,
});

export const SetProductAction = (product: OrderProduct) => ({
  type: ORDER_ACTIONS.SET_PRODUCT,
  payload: product,
});

export const CleanupOrderFlowState = () => ({
  type: ORDER_ACTIONS.CLEANUP_ORDER_FLOW_STATE,
});
