import React from "react";
import { Container, makeStyles, Theme } from "@material-ui/core";
import { useCustomer } from "app/store/hooks";
import { CUSTOMER_TYPES } from "app/store/reducer/order/types";
import { Stepper } from "components/Stepper";
import { CheckoutFlowStep } from "components/CheckoutStepper/types";

export type StepperProps = {
  currentStep: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: "none",
    paddingLeft: theme.spacing(5),
    maxWidth: "1040px",
    margin: "0 auto",

    [theme.breakpoints.up("sm")]: {
      display: "block",
      marginTop: theme.spacing(8),
    },
  },
}));

const generateSteps = (customerType: string) => {
  switch (customerType) {
    case CUSTOMER_TYPES.GUEST:
      return [
        CheckoutFlowStep.ACCOUNT_DETAILS,
        CheckoutFlowStep.PAYMENT_DETAILS,
        CheckoutFlowStep.CONFIRMATION,
      ];
    case CUSTOMER_TYPES.NEW:
      return [
        CheckoutFlowStep.ACCOUNT_DETAILS,
        CheckoutFlowStep.EMAIL_VERIFICATION,
        CheckoutFlowStep.PAYMENT_DETAILS,
        CheckoutFlowStep.CONFIRMATION,
      ];
    default:
      return [
        CheckoutFlowStep.EMAIL_VERIFICATION,
        CheckoutFlowStep.ACCOUNT_DETAILS,
        CheckoutFlowStep.PAYMENT_DETAILS,
        CheckoutFlowStep.CONFIRMATION,
      ];
  }
};

export const CheckoutStepper: React.FC<StepperProps> = ({ currentStep }) => {
  const classes = useStyles();

  const customer = useCustomer();
  const customerType = customer?.customerType;

  const steps: string[] = generateSteps(customerType);

  return (
    <Container className={classes.wrapper}>
      <Stepper steps={steps} currentStep={currentStep} />
    </Container>
  );
};

export default CheckoutStepper;
