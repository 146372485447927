import { makeStyles, Theme } from "@material-ui/core";

const LINE_LENGTH = 80;

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
  },

  step: {
    position: "relative",
    color: "#0C161F",

    "&:not(:last-child)": {
      marginRight: `${LINE_LENGTH}px`,

      "&::after": {
        content: "''",
        position: "absolute",
        right: `-${LINE_LENGTH}px`,
        width: `${LINE_LENGTH}px`,
        top: "50%",
        transform: "translateY(-50%)",
        borderBottom: "2px solid #9C9C9C",
      },
    },
  },

  stepName: {
    marginRight: theme.spacing(1),
  },

  stepNumber: {
    display: "inline-block",
    marginRight: theme.spacing(1),
  },

  stepNumberInner: {
    display: "grid",
    placeItems: "center",
    width: "25px",
    height: "25px",
    fontSize: "14px",
    color: "#9C9C9C",
    backgroundColor: "#EDEDED",
    borderRadius: "50%",
  },

  stepActive: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
  },
}));
