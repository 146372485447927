import React, { FC } from "react";
import { Typography, Button, Alert } from "@welldigital/components";
import ChevronRight from "@material-ui/icons/ChevronRight";

export const RenderCancelled: FC<{}> = () => {
  return (
    <>
      <Alert
        message={
          <>
            <Typography
              color={"inherit"}
              variant={"subtitle2"}
              spacingAfter={1}
            >
              You have cancelled your subscription
            </Typography>
            <Typography color={"inherit"} variant={"caption"}>
              We’ve sent you an email to confirm your cancellation. If you’d
              like to restart your subscription in the future you’ll need to
              complete your online consultation again.
            </Typography>
          </>
        }
        type={"success"}
        spacingAfter={4}
      />
      <Button
        color={"primary"}
        endIcon={<ChevronRight />}
        size={"large"}
        fullWidth
        // https://github.com/mui-org/material-ui/issues/22452 this is fixed in version 5 of material ui
        // @ts-ignore
        target={"_blank"}
        href={"https://www.well.co.uk/pharmacy-services"}
      >
        Explore our services
      </Button>
    </>
  );
};

export default RenderCancelled;
