import React, { HTMLAttributes } from "react";
import classNames from "clsx";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: {
    background: "#F7FAFF",
    borderRadius: "8px",
    padding: "25px",
    "&:not(:last-child)": {
      marginBottom: "28px",
    },
  },
}));

export const InfoBox: React.FC<HTMLAttributes<HTMLDivElement>> = ({
  children,
  ...props
}) => {
  const classes = useStyles();
  return (
    <div {...props} className={classNames(classes.root, props.className)}>
      {children}
    </div>
  );
};
