import { addImagesToOrderProductsMeta } from "../orders/helpers";

export const SUBSCRIPTION_STATUSES: any = {
  REQUIRES_APPROVAL: "Requires Approval",
  REQUIRES_PHARMACIST_APPROVAL: "Requires pharmacist approval",
  ACTIVE: "Active",
  CANCELLED: "Cancelled",
  APPROVED: "Approved",
  PAUSED_NO_APPROVAL: "No approval",
  PAUSED_FAILED_PAYMENT: "Failed payment",
};

export const transformSubscription = (subscriptionObject: any) => {
  //this is provisory until the images are hosted on server side
  addImagesToOrderProductsMeta(subscriptionObject);
  const product = subscriptionObject.products[0].product;

  const isCancelled = subscriptionObject?.status === "CANCELLED";

  return {
    ...subscriptionObject,
    type: "subscription",
    typeLabel: "Subscription",
    title: product.productInfo.productName,
    status:
      SUBSCRIPTION_STATUSES[subscriptionObject.status] ||
      subscriptionObject.status,
    content: [
      {
        label: "Start Date",
        value: subscriptionObject.createdDate,
      },
      {
        label: "Renewal Date",
        value: isCancelled
          ? "No Date Available"
          : subscriptionObject.nextOrderDate,
      },
      {
        label: "Status",
        value: SUBSCRIPTION_STATUSES[subscriptionObject.status],
      },
      {
        label: "Quantity",
        value: `${product.meta.strength}mg ${product.itemsPerPack} tablets`,
      },
    ],
  };
};
