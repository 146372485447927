import { RSAA } from "redux-api-middleware";
import { PostcodeLookupAddress } from "../shared";

export interface State {
  customerDetails: CustomerDetails;
  confirmedPayment: boolean;
  braintreeToken: string;
  paymentErrorMessage: string;
  postCodeLookUpAddresses: PostcodeLookupAddress[];
  deliveryDetails: DeliveryDetails;
}

export interface CustomerDetails {
  title?: string;
  firstName: string;
  lastName: string;
  line1: string;
  line2: string;
  city: string;
  postcode: string;
  email: string;
  phone: string;
  surgeryName?: string;
  surgeryAddress?: string;
  allowMarketing?: boolean;
}

export const ADD_CUSTOMER_DETAILS = "CUSTOMER/ADD_CUSTOMER_DETAILS";

export interface AddCustomerDetailsAction {
  type: string;
  payload: {
    customerDetails: CustomerDetails;
  };
}

export const emptyCustomerDetails: CustomerDetails = {
  firstName: "",
  lastName: "",
  line1: "",
  line2: "",
  city: "",
  postcode: "",
  email: "",
  phone: "",
  surgeryName: "",
  surgeryAddress: "",
  allowMarketing: false,
};

export interface DeliveryDetails {
  title?: string;
  firstName: string;
  lastName: string;
  line1: string;
  line2: string;
  city: string;
  postcode: string;
}

export const ADD_DELIVERY_DETAILS = "CUSTOMER/ADD_DELIVERY_DETAILS";

export interface AddDeliveryDetailsAction {
  type: string;
  payload: {
    deliveryDetails: DeliveryDetails;
  };
}

export const emptyDeliveryDetails: DeliveryDetails = {
  title: "",
  firstName: "",
  lastName: "",
  line1: "",
  line2: "",
  city: "",
  postcode: "",
};

export const FETCH_BRAINTREE_TOKEN = "PAYMENT/FETCH_BRAINTREE_TOKEN";
export const FETCH_BRAINTREE_TOKEN_REQUEST =
  "PAYMENT/FETCH_BRAINTREE_TOKEN_REQUEST";
export const FETCH_BRAINTREE_TOKEN_RESPONSE =
  "PAYMENT/FETCH_BRAINTREE_TOKEN_RESPONSE";
export const FETCH_BRAINTREE_TOKEN_FAILURE =
  "PAYMENT/FETCH_BRAINTREE_TOKEN_FAILURE";

export interface FetchBraintreeTokenAction {
  type: string;
  [RSAA]: {
    endpoint: string;
    method: string;
    types: [string, string, string];
  };
}

export interface FetchBraintreeTokenResponseAction {
  type: string;
  payload: {
    token: string;
  };
}

export interface PaymentFailureAction {
  type: string;
  payload: {
    response: {
      message: string | null;
    } | null;
  };
}

export const FETCH_ADDRESS_FROM_POSTCODE =
  "ADDRESS/FETCH_ADDRESS_FROM_POSTCODE";
export const FETCH_ADDRESS_FROM_POSTCODE_REQUEST =
  "ADDRESS/FETCH_ADDRESS_FROM_POSTCODE_REQUEST";
export const FETCH_ADDRESS_FROM_POSTCODE_RESPONSE =
  "ADDRESS/FETCH_ADDRESS_FROM_POSTCODE_RESPONSE";
export const FETCH_ADDRESS_FROM_POSTCODE_FAILURE =
  "ADDRESS/FETCH_ADDRESS_FROM_POSTCODE_FAILURE";

export interface FetchAddressFromPostcodeActionResponse {
  type: string;
  payload: {
    addresses: PostcodeLookupAddress[];
  };
}
