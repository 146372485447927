import React from "react";
import clsx from "clsx";
import { Grid } from "@material-ui/core";
import { useStyles } from "./styles";

export type StepperProps = {
  steps: string[];
  currentStep: string;
};

export const Stepper: React.FC<StepperProps> = ({ steps, currentStep }) => {
  const classes = useStyles();

  const currentStepIndex = steps.indexOf(currentStep);
  const activeSteps = steps.slice(0, currentStepIndex + 1);

  return (
    <div>
      <Grid container spacing={2}>
        {steps.map((step, index) => (
          <Grid item key={step} className={classes.step}>
            <span className={classes.stepNumber}>
              <div
                className={clsx(
                  classes.stepNumberInner,
                  activeSteps.includes(step) && classes.stepActive
                )}
              >
                {index + 1}
              </div>
            </span>
            <span className={classes.stepName}>{step}</span>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default Stepper;
