import React, { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Grid, Box } from "@material-ui/core";
import ChevronRight from "@material-ui/icons/ChevronRight";
import {
  Input,
  Typography,
  Button,
  ButtonProps,
  RadioSelect,
  RadioSelectProps,
  Divider,
} from "@welldigital/components";
import { analytics } from "@welldigital/ui-common/Analytics";

import events from "utils/events";
import { useStyles } from "pages/OnlineServicesOrderDetailsPage/styles";
import { OrderDetailsProduct } from "pages/OnlineServicesOrderDetailsPage/types";
import { UpdateSubscriptionHealthStatusPayload } from "utils/api/SubscriptionsAPI";

import { updateSubscriptionHealthStatus } from "app/store/reducer/subscriptions/actions";

interface Props {
  performPageDataRefetch: () => void;
  onError: React.Dispatch<React.SetStateAction<string>>;
  product: OrderDetailsProduct;
}

export const RenderCustomerConfirmation: React.FC<Props> = ({
  performPageDataRefetch,
  onError,
  product,
}) => {
  const [hasHealthStatusChanged, setHasHealthStatusChanged] = useState(false);
  const [value, setValue] = useState("");
  const [isButtonBusy, setIsButtonBusy] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();

  const healthStatusChangeToggle = useCallback(() => {
    setHasHealthStatusChanged((prev) => !prev);

    analytics.trackEvent({
      flow: "subscription",
      event: events.subscription.viewHealthStatusConfirmation,
      metadata: {
        subscription: {
          sku: product.sku,
        },
      },
    });
  }, [product.sku]);

  const handleValueChange = useCallback((value: string) => setValue(value), []);

  const sendSubscriptionHealthStatus = useCallback(
    (reason: string) => async () => {
      const payload: UpdateSubscriptionHealthStatusPayload = {
        subscriptionId: id,
      };

      if (reason === "changed") {
        payload.details = value;
      }

      analytics.trackEvent({
        flow: "subscription",
        event: events.subscription.submitHealthStatusConfirmation,
        metadata: {
          subscription: {
            sku: product.sku,
            hasHealthStatusChanged: hasHealthStatusChanged,
          },
        },
      });

      setIsButtonBusy(true);

      dispatch(updateSubscriptionHealthStatus(payload))
        .then(() => {
          performPageDataRefetch();
        })
        .catch((e: any) => {
          onError(
            "An error occured when sending cofirmation. Please try again"
          );
        })
        .finally(() => {
          setIsButtonBusy(false);
        });
    },
    [
      id,
      value,
      onError,
      dispatch,
      product.sku,
      performPageDataRefetch,
      hasHealthStatusChanged,
    ]
  );

  const radioSelectProps: RadioSelectProps = {
    variant: "contained",
    fullWidth: true,
    options: [],
    onChange: healthStatusChangeToggle,
    value: hasHealthStatusChanged,
    disabled: isButtonBusy,
  };

  const buttonProps: ButtonProps = {
    size: "large",
    color: "primary",
    fullWidth: true,
    endIcon: <ChevronRight />,
    loading: isButtonBusy,
  };

  return (
    <>
      <Typography className={classes.subTitle} spacingAfter={2}>
        Has anything changed with your health since your last subscription?
      </Typography>
      <Typography className={classes.paragraph}>
        It’s important to only continue taking your medication if it’s still
        safe for you to do so. Please advise us if there have been any changes
        to your health since you last recieved your subscription.
      </Typography>
      <Box mt={2} mb={3}>
        <Grid container spacing={4}>
          <Grid item sm={6}>
            <RadioSelect
              {...radioSelectProps}
              options={[
                {
                  label: "Yes",
                  id: true,
                },
              ]}
            />
          </Grid>
          <Grid item sm={6}>
            <RadioSelect
              {...radioSelectProps}
              options={[
                {
                  label: "No",
                  id: false,
                },
              ]}
            />
          </Grid>
        </Grid>
      </Box>

      {hasHealthStatusChanged && (
        <>
          <Typography className={classes.subTitle} spacingAfter={2}>
            We need more information
          </Typography>
          <Typography className={classes.paragraph} spacingAfter={2}>
            Please use the box below to tell us a bit more about what has
            changed. We will then send a message to our pharmacist to review the
            changes.
          </Typography>
          <Box mb={4}>
            <Input
              multiline
              fullWidth
              value={value}
              onChange={handleValueChange}
              inputProps={{
                className: classes.healthStatusInput,
              }}
              FormHelperTextProps={{
                style: { marginLeft: 0 },
              }}
              placeholder={"Please enter here"}
            />
          </Box>
        </>
      )}

      {hasHealthStatusChanged ? (
        <Button
          {...buttonProps}
          disabled={!(value.length > 0)}
          onClick={sendSubscriptionHealthStatus("changed")}
        >
          Send information
        </Button>
      ) : (
        <Button
          {...buttonProps}
          onClick={sendSubscriptionHealthStatus("unchanged")}
        >
          Confirm next subscription
        </Button>
      )}
      <Divider spacingBefore={4} />
    </>
  );
};

export default RenderCustomerConfirmation;
