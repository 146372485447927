import { AxiosResponse } from "axios";
import { AnyAction } from "redux";
import SubscriptionsAPI, {
  UpdateSubscriptionHealthStatusPayload,
} from "utils/api/SubscriptionsAPI";

import { ACTION_STATUSES, TActionStatus } from "app/store/types";
import { TSubscriptions, TSubscription } from "./types";

import { transformSubscription } from "./helpers";

export const SUBSCRIPTIONS_ACTIONS: { [key: string]: string } = {
  SET_SUBSCRIPTION: "SET_SUBSCRIPTION",
  SET_SUBSCRIPTIONS: "SET_SUBSCRIPTIONS",
  GET_SUBSCRIPTIONS: "GET_SUBSCRIPTIONS",
  SET_SUBSCRIPTIONS_STATUS: "SET_SUBSCRIPTIONS_STATUS",
};

export type OrderActionTypes = {
  SET_SUBSCRIPTIONS: AnyAction;
  SET_SUBSCRIPTIONS_STATUS: AnyAction;
};

export const setSubscriptionsData = (subscriptions: TSubscriptions) => ({
  type: SUBSCRIPTIONS_ACTIONS.SET_SUBSCRIPTIONS,
  payload: subscriptions,
});

export const setSubscriptionDataById = (
  id: number,
  subscription: TSubscription
) => ({
  type: SUBSCRIPTIONS_ACTIONS.SET_SUBSCRIPTION,
  payload: { id, subscription } as any,
});

export const setSubscriptionsStatus = (status: TActionStatus) => ({
  type: SUBSCRIPTIONS_ACTIONS.SET_SUBSCRIPTIONS_STATUS,
  payload: status,
});

export type TAny = (payload?: any) => any;

export const getSubscriptions: TAny = () => async (dispatch: any) => {
  await dispatch(setSubscriptionsStatus(ACTION_STATUSES.PENDING));

  try {
    const subscriptions =
      (await SubscriptionsAPI.getSubscriptions()) as AxiosResponse;

    const subscriptionsData = await subscriptions.data.subscriptions;

    const mappedSubscriptions = subscriptionsData.map(transformSubscription);
    dispatch(setSubscriptionsData(mappedSubscriptions));
    dispatch(setSubscriptionsStatus(ACTION_STATUSES.LOADED));
  } catch (e) {
    console.warn(e);
    dispatch(setSubscriptionsStatus(ACTION_STATUSES.FAILED));
  }
};

export const getSubscriptionById: TAny = (id) => async (dispatch: any) => {
  await dispatch(setSubscriptionsStatus(ACTION_STATUSES.PENDING));

  try {
    const subscription = (await SubscriptionsAPI.getSubscription(
      id
    )) as AxiosResponse;

    const subscriptionData = await subscription.data;

    const mappedSubscription = transformSubscription(subscriptionData);
    dispatch(setSubscriptionDataById(id, mappedSubscription));
    dispatch(setSubscriptionsStatus(ACTION_STATUSES.LOADED));
  } catch (e) {
    console.warn(e);
    dispatch(setSubscriptionsStatus(ACTION_STATUSES.FAILED));
  }
};

export const updateSubscriptionHealthStatus: TAny =
  (payload: UpdateSubscriptionHealthStatusPayload) => async () => {
    const response = (await SubscriptionsAPI.updateSubscriptionHealthStatus(
      payload
    )) as AxiosResponse;
    return response;
  };
