import React, { FC } from "react";
import { Button, Feature, FeatureProps, InfoBox, Text } from "../../components";
import ChatIcon from "@material-ui/icons/Chat";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import CheckIcon from "@material-ui/icons/Check";
import ComputerIcon from "@material-ui/icons/Computer";
import LockIcon from "@material-ui/icons/Lock";
import { Box, Container } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { OnlineServiceParamTypes } from "../../app/store/reducer/online/types";
import GeneralLayout from "app/layouts/OnlineServices/GeneralLayout/GeneralLayout";

export const OnlineServicesStart: FC = () => {
  const { onlineServiceId } = useParams<OnlineServiceParamTypes>();
  const mainFeatures: FeatureProps[] = [
    {
      icon: ChatIcon,
      title: "Complete your online consultation",
      description: "Start your free, private online consultation now",
    },
    {
      icon: ShoppingCartIcon,
      title: "Choose your recommended treatment and quantity",
      description: "Based on your consultation, add your order to your basket",
    },
    {
      icon: CheckIcon,
      title: "Receive your product with free discreet delivery",
      description:
        "We'll send your order discreetly to your delivery address for free",
    },
  ];
  const infoFeatures: FeatureProps[] = [
    {
      icon: ComputerIcon,
      title: "No need to visit a pharmacist",
      description:
        "These questions are approved by our pharmacy team. This means you get the same service online as you would in one of our stores.",
    },
    {
      icon: LockIcon,
      title: "Private and secure",
      description:
        "All information you provide is completely confidential. Your responses are private and we won't ask for any personal details during your consultation. Remember the questions we ask are standard and nothing to be embarrassed about.",
    },
  ];

  return (
    <GeneralLayout>
      <Box py={6} px={2}>
        <Container maxWidth={"xs"} disableGutters>
          <Text variant={"beforeTitle"}>Let’s get started</Text>
          <Text variant={"title"}>Complete your free online consultation</Text>
          <Text variant={"paragraph"}>
            We need to ask you a few questions to find a suitable erectile
            dysfunction treatment for you.
          </Text>
          {mainFeatures.map((feature, k) => (
            <Feature key={k} {...feature} />
          ))}
          <Box marginY={"38px"}>
            <Button
              data-testid={"next-button"}
              href={`/order/${onlineServiceId}/consultation`}
              variant={"formNext"}
            >
              Start your consultation
            </Button>
          </Box>
          <InfoBox>
            {infoFeatures.map((feature, k) => (
              <Feature key={k} {...feature} />
            ))}
          </InfoBox>
          <Text variant={"disclaimer"}>
            Bestway National Chemists Limited is registered in England and
            Wales, trading as Well and Well Pharmacy. Our online pharmacy
            (well.co.uk) registration number is 9010492
            <br />
            <br />
            Our Superintendent Pharmacist is Iftkhar Ahmad Khan, FRPharmS.
            <br />
            GPhC Registration Number: 2041286.
          </Text>
        </Container>
      </Box>
    </GeneralLayout>
  );
};
