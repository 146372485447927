import React, { FC, useCallback, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import {
  OnlineQuestion,
  OnlineServiceIds,
  OnlineServiceParamTypes,
  SuccessfulConsultationResponse,
} from "app/store/reducer/online/types";
import { analytics } from "@welldigital/ui-common/Analytics";
import { isHana1315Flow } from "../../utils/onlineServiceConsultation";
import { Text } from "../../components";
import events, { eventsBuilder } from "utils/events";

export interface OnlineServicesConsultationDispatchProps {
  runTripetto(
    onlineServiceId: string,
    element: React.MutableRefObject<null>,
    handleSuccessfulConsultation: (
      data: SuccessfulConsultationResponse[]
    ) => void,
    onAnswerEditStarted: (question: string) => void
  ): void;
  setConsultation(service: string, c: OnlineQuestion[]): void;
  setIsConsultationValidForOrder(payload: boolean): void;
  sendHana1315Email(d: OnlineQuestion[]): void;
}

export const OnlineServicesConsultation: FC<OnlineServicesConsultationDispatchProps> =
  ({
    runTripetto,
    setConsultation,
    sendHana1315Email,
    setIsConsultationValidForOrder,
  }) => {
    const { onlineServiceId } = useParams<OnlineServiceParamTypes>();

    useEffect(() => {
      analytics.trackEvent({
        flow: onlineServiceId,
        event: events.online.consultation.started,
      });

      const hasReferral = document.referrer && document.referrer !== "";
      const refferal = hasReferral ? document.referrer : "UNAVAILABLE";

      analytics.trackEvent({
        flow: onlineServiceId,
        event: eventsBuilder.consultation.start,
        metadata: { refferal },
      });
    }, [onlineServiceId]);

    const handleSuccessfulConsultation = useCallback(
      (data: SuccessfulConsultationResponse[]) => {
        const values: OnlineQuestion[] = data
          .filter(({ answer }) => answer !== undefined)
          .filter(
            ({ kind, answer }) => kind !== "tripetto-block-checkboxes" || answer
          )
          .map((response, i) => ({
            number: i,
            question: response.question,
            answer: response.answerString,
            type: response.type,
          }));

        let successMarker;

        if (values[values.length - 1]?.question === "Successful consultation") {
          successMarker = values.pop();
          if (successMarker?.answer === "true") {
            setIsConsultationValidForOrder(true);
          }
        }

        setConsultation(onlineServiceId, values);

        analytics.trackEvent({
          flow: onlineServiceId,
          event: events.online.consultation.completed,
        });

        if (successMarker?.answer) {
          analytics.trackEvent({
            flow: onlineServiceId,
            event: eventsBuilder.consultation.complete,
          });
        } else {
          const lastQuestion = values.pop();

          analytics.trackEvent({
            flow: onlineServiceId,
            event: eventsBuilder.consultation.ineligible,
            metadata: { lastQuestion: lastQuestion?.question },
          });
        }

        if (
          onlineServiceId === OnlineServiceIds.hana &&
          isHana1315Flow(values)
        ) {
          sendHana1315Email(values);
        }
      },
      [
        setConsultation,
        onlineServiceId,
        sendHana1315Email,
        setIsConsultationValidForOrder,
      ]
    );

    const inputEl = useRef(null);

    useEffect(() => {
      if (!inputEl || !inputEl.current) {
        return;
      }
      const onAnswerEdit = (question: string) => {
        analytics.trackEvent({
          flow: onlineServiceId,
          event: events.online.consultation.answeringQuestion,
          metadata: { question },
        });

        analytics.trackEvent({
          flow: onlineServiceId,
          event: eventsBuilder.consultation.answer,
          metadata: { question },
        });
      };

      runTripetto(
        onlineServiceId,
        inputEl,
        handleSuccessfulConsultation,
        onAnswerEdit
      );
    }, [inputEl, runTripetto, onlineServiceId, handleSuccessfulConsultation]);

    return (
      <>
        <Text variant={"label"}>Let’s get started</Text>
        <Text variant={"title"}>Complete your free online consultation</Text>
        <Text variant={"paragraph"}>
          All information you provide is completely confidential. We don't ask
          for your personal details during your consultation. You'll only need
          to enter your details when placing an order.
        </Text>
        <div ref={inputEl} />
      </>
    );
  };
