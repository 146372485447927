export default {
  online: {
    consultation: {
      started: "Consultation started", // as the consultation is being loaded
      answeringQuestion: `Consultation answering question`, // when a user begins answering a question
      completed: "Consultation complete", // when the consultation is finished (when tripetto starts showing the epilogue)
    },
    productSelection: "Select product",
  },
  order: {
    tapOnSubscription: "tap on subscription",
    tapOnNoSubscription: "tap on no subscription",
    subscriptionMoreInfo: "tap on subscription more info",
    selectProductWithSubscription:
      "tap on continue to checkout with subscription",
    selectProductWithoutSubscription:
      "tap on continue to checkout one off purchase",
    deliveryDetails: "delivery details",
    paymentSuccess: "payment complete",
    confirmation: "confirmation complete",
  },
  subscription: {
    viewHealthStatusConfirmation: "view health status confirmation",
    submitHealthStatusConfirmation: "submit health status confirmation",
  },
  registration: {
    customer: "customer registration",
  },
};

export const eventsBuilder = {
  consultation: {
    start: "Start consultation CTA",
    answer: "Question answered",
    ineligible: "Ineligible",
    complete: "Consultation Complete",
  },
  productSelect: {
    productCardSelectButton: (product?: any) => `${product} selected`,
    packSelected: (
      productName?: string,
      productStrength?: string,
      productPackSize?: string,
      isSubscription?: boolean
    ) =>
      `${productName} ${productStrength}mg ${productPackSize} ${
        isSubscription ? "with" : "without"
      } subscription selected`,
    seeMoreAboutSubscription: "Clicked subscription info",
    choseProduct: "ChoseProduct",
  },
  basket: {
    discountApplied: "Discount Code Applied",
    discountFailed: "Discount Code Failed",
  },
  loginOptions: {
    signIn: "Chose Sign In",
    signInWithSub: "Chose Sign In With Subscription",
    register: "Chose Register",
    registerWithSub: "Chose Register With Subscription",
    guestCheckout: "Chose guest checkout",
    guestCheckoutWithSub: "Chose guest checkout with subscription",
    guestRemoveSub: "Guest checkout: Removed subscription",
    guestBackToCheckout: "Guest checkout: Chose back to checkout option",
  },
  orderDetailsForm: {
    newUser: {
      postcodeLookup: {},
    },
    existingUser: {
      postcodeLookup: "Delivery details: Postcode lookup",
      postcodeFailed: "Delivery details: Postcode lookup failed",
      addressChangeModal: "Address change modal",
      addressChangeSubmit: "New delivery address submitted",
    },
  },
  account: {
    dashboard: "Account dashboard",
  },
};
