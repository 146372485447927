import React, { useCallback, useState, useRef, useMemo } from "react";
import { debounce } from "lodash";
import { Field, Input, InputProps } from "@welldigital/components";
import { useFormContext } from "hooks";
import AddressAPI from "utils/api/AddressAPI";
import AddressListPopper from "components/AddressListPopper";

export const validateGPAddress = (value: InputProps["value"]) => {
  if (!value) {
    return "Please enter your GP address";
  }
  return true;
};

interface Props {
  onSearchError: (message: string) => void;
}

export const AddressLookupGP: React.FC<Props> = ({ onSearchError }) => {
  const [gpLookupAddresses, setGpLookupAddresses] = useState([]);
  const inputRef = useRef<HTMLInputElement | null>();
  const { form } = useFormContext();

  const fetchGpAddresses = useCallback(
    async (query: string) => {
      try {
        const response = await AddressAPI.getAddressByGP(query);
        const data = response.data?.data;

        setGpLookupAddresses(data ?? []);
      } catch (err) {
        onSearchError(
          "An error occured when searching for GP details, please try again"
        );
      }
    },
    [onSearchError]
  );

  const handleAddressSelect = useCallback(
    (address) => {
      form.setValue(
        "gpDetails",
        `${address.name}, ${address.addressLine1}, ${address.addressLine2}${
          address.addressLine2 ? ", " : ""
        }${address.city}, ${address.postcode}`
      );
      setGpLookupAddresses([]);
    },
    [form]
  );

  const searchAddresses = useCallback(
    (query: string) => {
      fetchGpAddresses(query);
    },
    [fetchGpAddresses]
  );

  const debouncedAddressSearchHandler = useMemo(
    () => debounce(searchAddresses, 300),
    [searchAddresses]
  );

  const resetAddressList = useCallback(() => {
    setGpLookupAddresses([]);
  }, []);

  return (
    <>
      <Field
        name={"gpDetails"}
        label={"GP name, address, postcode"}
        component={Input}
        validate={validateGPAddress}
        required
        onChange={debouncedAddressSearchHandler}
        inputRef={inputRef}
        inputProps={{
          style: { minWidth: "120px" },
          autoComplete: "off",
          form: {
            autoComplete: "off",
          },
        }}
        disabled={false}
        defaultValue={""}
      />
      <AddressListPopper
        isOpen={gpLookupAddresses.length > 0}
        anchorEl={inputRef.current}
        addressList={gpLookupAddresses}
        onClickAddress={handleAddressSelect}
        onClickAway={resetAddressList}
      />
    </>
  );
};

export default AddressLookupGP;
