import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "@welldigital/ui-common/Utils/polyfills";

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import { analytics } from "@welldigital/ui-common/Analytics";
import { AuthenticationProvider } from "@welldigital/ui-common/Authentication";

import App from "./app/App";
import * as serviceWorker from "./serviceWorker";

import { store } from "./app/store";

import { LOGIN_TARGET } from "constants/login";

import "@welldigital/components/font/index.css";

analytics.init();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <AuthenticationProvider loginTarget={LOGIN_TARGET}>
        <App />
      </AuthenticationProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorker.unregister();
