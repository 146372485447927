import { Pack as OnlinePack, Product as OnlineProduct } from "../online/types";

// Easy enough passthrough for props. When needed, we can break this off.

export type Pack = OnlinePack;

export type PackDiscount = {
  code: string;
  type: "percentage" | "fixed";
  discountInfo: number;
  discountAmount: number;
  priceWithDiscount: number;
};

export type Discount = {
  initial: number;
  discountValue: number;
  final: number;
  discounts?: null | Array<{
    code: string;
    type: PackDiscount["type"];
    amount: number;
    calculated: {
      initial: number;
      value: number;
      final: number;
    };
  }>;
};

export type Product = OnlineProduct & {
  subscription?: boolean;
  image?: string;
  delivery?: string;
  orderId?: string;
  subscriptionId?: string;
};

export type OrderCustomer = {
  customerType: string;
  customerDetails: null | OrderCustomerDetails;
};

export type OrderProduct = Product | null;

export interface OrderCustomerDetails {
  firstName: string;
  lastName: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  postcode: string;
  email: string;
  phone: string;
  dob?: string;
  gpDetails?: string;
  agreeMarketing: boolean;
}

export type State = {
  customer: OrderCustomer;
  product: OrderProduct;
};

export type TOrder = State;

export const CUSTOMER_TYPES = {
  GUEST: "GUEST",
  EXISTING: "EXISTING",
  NEW: "NEW",
  PENDING: "PENDING", // Used to immediately trigger registration when redirected from sign-in PIN page
};
