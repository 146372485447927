import { AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { TAny } from "app/store/reducer/subscriptions/actions";
import OrdersAPI from "utils/api/OrdersAPI";

import { ACTION_STATUSES, TActionStatus } from "app/store/types";
import { TOrder, TOrders } from "./types";

import { transformOrder } from "./helpers";

export const ORDERS_ACTIONS: { [key: string]: string } = {
  SET_ORDER: "SET_ORDER",
  SET_ORDERS: "SET_ORDERS",
  GET_ORDERS: "GET_ORDERS",
  SET_ORDERS_STATUS: "SET_ORDERS_STATUS",
};

export type OrdersActionTypes = {
  SET_ORDERS: AnyAction;
  SET_ORDERS_STATUS: AnyAction;
};

export const setOrdersData = (orders: TOrders) => ({
  type: ORDERS_ACTIONS.SET_ORDERS,
  payload: orders,
});

export const setOrderDataById = (id: number, order: TOrder) => ({
  type: ORDERS_ACTIONS.SET_ORDER,
  payload: { id, order } as any,
});

export const setOrdersStatus = (status: TActionStatus) => ({
  type: ORDERS_ACTIONS.SET_ORDERS_STATUS,
  payload: status,
});

export const getOrderById: TAny = (id) => async (dispatch: any) => {
  dispatch(setOrdersStatus(ACTION_STATUSES.PENDING));

  try {
    const order: AxiosResponse = await OrdersAPI.getOrder(id);

    const ordersData = transformOrder(order.data);

    dispatch(setOrderDataById(id, ordersData));
    dispatch(setOrdersStatus(ACTION_STATUSES.LOADED));
  } catch (e) {
    console.warn(e);
    dispatch(setOrdersStatus(ACTION_STATUSES.FAILED));
  }
};

export const getOrders: TAny = () => async (dispatch: any) => {
  dispatch(setOrdersStatus(ACTION_STATUSES.PENDING));

  try {
    const orders = (await OrdersAPI.getOrders()) as AxiosResponse;
    const ordersData = orders.data.orders.map(transformOrder);

    dispatch(setOrdersData(ordersData));
    dispatch(setOrdersStatus(ACTION_STATUSES.LOADED));
  } catch (e) {
    console.warn(e);
    dispatch(setOrdersStatus(ACTION_STATUSES.FAILED));
  }
};
