/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { pick, omit, isEqual } from "lodash";
import ChevronRight from "@material-ui/icons/ChevronRight";
import { Form, Button, Typography } from "@welldigital/components";
import AppModal from "components/AppModal";
import { FormValues, Scenarios } from "pages/OrderShippingDetails/types";
import FormFields from "pages/OrderShippingDetails/FormFields";
import { useStyles } from "pages/OrderShippingDetails/styles";
import { useAuthentication } from "@welldigital/ui-common/Authentication";
import {
  getCustomerDetailsFromValues,
  sendDeliveryDetailsEvent,
  redirectToPINConfirmationPage,
} from "pages/OrderShippingDetails/helpers";
import {
  CUSTOMER_TYPES,
  OrderCustomerDetails,
} from "app/store/reducer/order/types";
import { useRegisterAccount } from "pages/OrderShippingDetails/hooks";
import { useCustomer, useSetCustomer, useProduct } from "app/store/hooks";
import { analytics } from "@welldigital/ui-common";
import { eventsBuilder } from "utils/events";

export type OrderDetailsFormProps = {
  initialValues: Partial<FormValues>;
  scenarios: Scenarios;
  onlineServicesUser: Partial<OrderCustomerDetails>;
  handleFormError: (message: string) => void;
  setIsPageLoading: React.Dispatch<React.SetStateAction<boolean>>;
  isBusy: boolean;
  isAuthenticated: boolean;
};

export const OrderDetailsForm: React.FC<OrderDetailsFormProps> = ({
  initialValues,
  scenarios,
  onlineServicesUser,
  handleFormError,
  setIsPageLoading,
  isBusy,
  isAuthenticated,
}) => {
  const [formValues, setFormValues] = useState<Partial<FormValues>>({});
  const [isAddressConfirmationModalOpen, setIsAddressConfirmationModalOpen] =
    useState(false);

  const history = useHistory();
  const product = useProduct();
  const customer = useCustomer();
  const setCustomer = useSetCustomer();
  const classes = useStyles({ pageContainer: { padding: "50px 0" } });
  const { email } = useAuthentication();
  const registerAccount = useRegisterAccount();

  const isSubscription = !!product?.subscription;
  const customerType = customer?.customerType;

  const {
    isAuthenticatedAndHasAccount,
    isAuthenticatedAndRequestsAccount,
    isNotAuthenticatedAndRequestsAccount,
    isNotAuthenticatedAndDoesNotRequestAccount,
  } = scenarios;

  const toggleModalState = useCallback(() => {
    if (!isAddressConfirmationModalOpen) {
      analytics.trackEvent({
        flow: "ed",
        event: eventsBuilder.orderDetailsForm.existingUser.addressChangeModal,
        metadata: {
          productName: product?.name,
          productSku: product?.packs[0].sku,
          packSize: product?.packs[0].itemsPerPack,
        },
      });
    }
    setIsAddressConfirmationModalOpen((prevState) => !prevState);
  }, []);

  const handleAddressChangeConfirmation = useCallback(() => {
    setCustomer({
      customerType,
      customerDetails: getCustomerDetailsFromValues(formValues),
    });
    analytics.trackEvent({
      flow: "ed",
      event: eventsBuilder.orderDetailsForm.existingUser.addressChangeSubmit,
      metadata: {
        productName: product?.name,
        productSku: product?.packs[0].sku,
        packSize: product?.packs[0].itemsPerPack,
      },
    });
    sendDeliveryDetailsEvent(product);
    history.push("/order/ed/payment");
    toggleModalState();
  }, [formValues, customerType, setCustomer, history, toggleModalState]);

  const handleSubmit = useCallback(
    (values: typeof initialValues) => {
      if (isAuthenticatedAndRequestsAccount) {
        setCustomer({
          customerType,
          customerDetails: getCustomerDetailsFromValues(values),
        });
        registerAccount({
          values: { ...omit(values, ["agreeTerms"]) },
          setIsPageLoading,
          handleFormError,
          redirect: "/order/ed/payment",
          isCheckoutFlow: true,
        });
        return;
      }

      if (isAuthenticatedAndHasAccount) {
        const existingAddress: Partial<OrderCustomerDetails> = {
          addressLine1: onlineServicesUser.addressLine1,
          addressLine2: onlineServicesUser.addressLine2,
          city: onlineServicesUser.city,
          postcode: onlineServicesUser.postcode,
        };
        const formAddress = pick(values, Object.keys(existingAddress));
        if (isEqual(existingAddress, formAddress)) {
          setCustomer({
            customerType,
            customerDetails: getCustomerDetailsFromValues({
              ...onlineServicesUser,
              ...values,
            }),
          });
          sendDeliveryDetailsEvent(product);
          history.push("/order/ed/payment");
        } else {
          const finalFormValues: Partial<FormValues> = {
            ...onlineServicesUser,
            ...omit(values, ["agreeTerms"]),
            email,
          };
          setFormValues(finalFormValues);
          toggleModalState();
        }
        return;
      }

      if (isNotAuthenticatedAndRequestsAccount) {
        setCustomer({
          customerType: CUSTOMER_TYPES.PENDING,
          customerDetails: getCustomerDetailsFromValues(values),
        });
        redirectToPINConfirmationPage(values.email!);
        return;
      }

      if (isNotAuthenticatedAndDoesNotRequestAccount) {
        setCustomer({
          customerType,
          customerDetails: getCustomerDetailsFromValues(values),
        });
        sendDeliveryDetailsEvent(product);
        history.push("/order/ed/payment");
        return;
      }
    },
    [
      initialValues,
      isAuthenticatedAndRequestsAccount,
      isAuthenticatedAndHasAccount,
      isNotAuthenticatedAndRequestsAccount,
      isNotAuthenticatedAndDoesNotRequestAccount,
      toggleModalState,
      email,
      onlineServicesUser,
      history,
      registerAccount,
      setCustomer,
      product,
      customerType,
      handleFormError,
      setIsPageLoading,
    ]
  );

  const handlePostcodeLookup = (success: boolean) => {
    analytics.trackEvent({
      flow: "ed",
      event: success
        ? eventsBuilder.orderDetailsForm.existingUser.postcodeLookup
        : eventsBuilder.orderDetailsForm.existingUser.postcodeFailed,
      metadata: {
        productName: product?.name,
        productSku: product?.packs[0].sku,
        packSize: product?.packs[0].itemsPerPack,
      },
    });
  };

  return (
    <>
      <Form
        fullWidth
        manualReset
        initialValues={initialValues}
        onSubmit={handleSubmit}
        spacingAfter={3}
      >
        <FormFields
          formValues={initialValues}
          // eslint-disable-next-line react/jsx-no-bind
          handlePostcodeSearch={handlePostcodeLookup}
          handleFormError={handleFormError}
          isBusy={isBusy}
          isSubscription={isSubscription}
          isAuthenticated={isAuthenticated}
          isGuest={isNotAuthenticatedAndDoesNotRequestAccount}
        />
        <div className={classes.flexContainer}>
          <Button variant={"text"} color={"default"} onClick={history.goBack}>
            Back
          </Button>
          <Button
            fullWidth
            size={"large"}
            type={"submit"}
            color={"primary"}
            endIcon={<ChevronRight />}
          >
            Next
          </Button>
        </div>
      </Form>
      {isAddressConfirmationModalOpen && (
        <AppModal
          isOpen={isAddressConfirmationModalOpen}
          title={"Change your delivery address"}
          onSuccess={handleAddressChangeConfirmation}
          onBack={toggleModalState}
        >
          <Typography align={"center"} spacingAfter={1} color={"inherit"}>
            It looks like you’re using a different delivery address than the one
            saved on your account. Please be aware that the new address will
            only be applied to this order. If you’ve ordered anything else, your
            items will be sent to your default delivery address
          </Typography>
          <Typography align={"center"} spacingAfter={6} color={"inherit"}>
            Would you like to continue?
          </Typography>
        </AppModal>
      )}
    </>
  );
};

export default OrderDetailsForm;
