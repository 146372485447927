import React, { FC, useState, useEffect } from "react";
import { Typography, Spacing, Button } from "@welldigital/components";
import { Container, Divider } from "@material-ui/core";
import OrderSummaryCard, {
  SummaryCardInfoType,
} from "components/OrderSummaryCard";
import ChevronRight from "@material-ui/icons/ChevronRight";
import { analytics } from "@welldigital/ui-common/Analytics";
import events from "utils/events";
import { Link } from "react-router-dom";
import { useStyles } from "./styles";
import { useSelector, useDispatch } from "react-redux";
import {
  useAuthentication,
  AUTHENTICATED_STATE,
} from "@welldigital/ui-common/Authentication";
import { RootState } from "app/store/types";
import { OrderProduct } from "app/store/reducer/order/types";
import { CleanupOrderFlowState } from "app/store/reducer/order/actions";
import GeneralLayout from "app/layouts/OnlineServices/GeneralLayout/GeneralLayout";

const getOrderInfo = (product: OrderProduct): SummaryCardInfoType[][] => [
  [
    {
      label: "Payment",
      value: "Confirmed",
    },
    {
      label: "Order Number",
      value: product?.orderId ?? "",
    },
  ],
  [
    {
      label: "Order Status",
      value: "Sent",
    },
    {
      label: "Estimated delivery",
      value: "2 to 4 days",
    },
  ],
];

type ConfirmationData = OrderProduct & {
  orderInfo: SummaryCardInfoType[][];
};

export const OnlineServicesCheckoutConfirmationPage: FC = () => {
  const classes = useStyles();
  const { authenticatedState } = useAuthentication();
  const isAuthenticated = authenticatedState === AUTHENTICATED_STATE.YES;
  const product = useSelector((state: RootState) => state.order.product);
  const dispatch = useDispatch();

  const [confirmationData, setConfirmationData] =
    useState<ConfirmationData | null>(null);

  useEffect(() => {
    if (product) {
      setConfirmationData({
        ...product,
        orderInfo: getOrderInfo(product),
      });

      dispatch(CleanupOrderFlowState());

      analytics.trackEvent({
        flow: product.service,
        event: events.order.confirmation,
        metadata: {
          product: {
            sku: product.packs[0].sku,
            productName: product.name,
            strength: product.variant?.split(" ")[0],
            subscription: product.subscription ? "YES" : "NO",
            packSize: product.packs[0].itemsPerPack,
          },
        },
      });
    }
  }, [product, dispatch]);

  return (
    <GeneralLayout>
      <Container maxWidth={"xs"} disableGutters className={classes.pageContent}>
        {!confirmationData && (
          <Typography
            className={classes.error}
            variant={"h2"}
            spacingAfter={6}
            align={"center"}
          >
            You don't have an order yet.
          </Typography>
        )}
        {confirmationData && (
          <>
            <Typography
              className={classes.pageTitle}
              variant={"h2"}
              spacingAfter={6}
            >
              Thank you, we have received your order.
            </Typography>
            <OrderSummaryCard
              image={confirmationData?.image as string}
              name={confirmationData?.name ?? ""}
              type={
                confirmationData?.subscription
                  ? "Subscription"
                  : "Individual Order"
              }
              info={confirmationData?.orderInfo || []}
            />
            <Spacing spacing={2} />
            <Divider classes={{ root: classes.divider }} />
            <Spacing spacing={4} />
            <Typography className={classes.sectionTitle} variant={"h3"}>
              What happens next?
            </Typography>
            <Typography className={classes.sectionText} spacingAfter={1}>
              Step 1
            </Typography>
            <Typography
              className={classes.sectionTitle}
              variant={"h3"}
              spacingAfter={2}
            >
              Pharmacist approval and secure checkout
            </Typography>
            <Typography className={classes.sectionText} spacingAfter={5}>
              One of our pharmacists will review your order to make sure your
              treatment is safe and suitable for you. If your order is approved,
              we will email you to confirm.
            </Typography>
            <Typography className={classes.sectionText} spacingAfter={4}>
              If you’ve placed your order before 3pm we aim to send you
              confirmation of your order on the same working day. If you order
              after 3pm or over the weekend, we will email you the next working
              day.
            </Typography>
            <Typography
              className={classes.sectionTitle}
              variant={"h4"}
              spacingAfter={1}
            >
              What if my order isn’t approved?
            </Typography>
            <Typography className={classes.sectionText} spacingAfter={4}>
              If our pharmacist doesn’t approve your order, we will send you an
              email to explain the reasoning, and automatically cancel your
              order.
            </Typography>
            <Divider classes={{ root: classes.divider }} />
            <Spacing spacing={4} />
            <Typography className={classes.sectionText} spacingAfter={1}>
              Step 2
            </Typography>
            <Typography
              className={classes.sectionTitle}
              variant={"h3"}
              spacingAfter={2}
            >
              Order dispatched
            </Typography>
            <Typography className={classes.sectionText} spacingAfter={5}>
              Once our pharmacist has approved your order and you’ve completed
              checkout, we’ll dispatch your order.
            </Typography>
            <Typography className={classes.sectionText} spacingAfter={4}>
              Dispatch will be on the same working day if you complete checkout
              before 3pm. If you complete checkout after 3pm, we’ll dispatch
              your order the next working day.
            </Typography>
            <Divider classes={{ root: classes.divider }} />
            <Spacing spacing={4} />
            <Typography className={classes.sectionText} spacingAfter={1}>
              Step 3
            </Typography>
            <Typography
              className={classes.sectionTitle}
              variant={"h3"}
              spacingAfter={2}
            >
              Delivery
            </Typography>
            <Typography className={classes.sectionText} spacingAfter={0}>
              We’ll email you to let you know your order is out for delivery.
              Our standard delivery is estimated to take 2-4 days.
            </Typography>
            <br />
            <br />
            {isAuthenticated && (
              <>
                <Spacing spacing={4} />
                <Button
                  fullWidth
                  variant={"contained"}
                  color={"primary"}
                  endIcon={<ChevronRight />}
                  size={"large"}
                  // https://github.com/mui-org/material-ui/issues/22452 this is fixed in version 5 of material ui
                  // @ts-ignore
                  component={Link}
                  to={`/account/${
                    confirmationData?.subscription ? "subscription" : "order"
                  }/${confirmationData?.orderId}`}
                >
                  Go to{" "}
                  {confirmationData?.subscription ? "subscription" : "order"}
                </Button>
              </>
            )}
          </>
        )}
      </Container>
    </GeneralLayout>
  );
};

export default OnlineServicesCheckoutConfirmationPage;
