import { makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) => ({
  pageContainer: { padding: "50px 0" },
  pageTitle: {
    fontSize: 34,
    fontWeight: 600,
    marginBottom: 20,
    padding: "0 16px",
  },
  pageHeadText: {
    fontSize: 18,
    fontWeight: 500,
    color: "#65727D",
    marginBottom: 50,
    padding: "0 16px",
  },
  contentLoader: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  largeScreenSelectedProductWrapper: {
    padding: "0 16px",
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  largeScreenSelectedProductWrapperInner: {
    display: "flex",
    flexDirection: "row",

    border: "2px solid #0059F0",
    boxSizing: "border-box",
    borderRadius: 6,
  },
  largeScreenSelectedProductInfoContainer: {
    width: "50%",
    backgroundColor: "#E0ECFD",
    padding: "0 16px 30px 16px",
  },
  largeScreenSelectedProductInfoTitle: {
    fontSize: 22,
    fontWeight: 700,
    margin: "24px 0",
  },
  largeScreenSelectedProductDetailsContainer: {
    width: "50%",
    padding: "0 0 30px 0",
  },
  largeScreenSelectedProductDetailsSubmitButton: {
    padding: "0 16px",
  },
}));
