import React, { FC } from "react";
import {
  makeStyles,
  Container,
  Toolbar,
  AppBar,
  Link,
} from "@material-ui/core";
import WellLogo from "app/layouts/OnlineServices/GeneralLayout/components/WellLogo";
import { UserProfile } from "@welldigital/ui-common/Authentication";
import { getUserProfileProps } from "../../../../../config/userProfile.config";

const useStyles = makeStyles({
  header: {
    height: 84,
    backgroundColor: "transparent",
  },
  headerContainer: {
    height: "100%",
  },
  logoLink: {
    textDecoration: "none",
  },
  logo: {
    width: "95px",
    height: "32px",
  },
  toolbar: {
    height: "100%",
    justifyContent: "space-between",
  },
});
const Header: FC<{}> = () => {
  const classes = useStyles();
  return (
    <AppBar
      classes={{ root: classes.header }}
      elevation={0}
      position={"static"}
      color={"inherit"}
    >
      <Container maxWidth={"lg"} className={classes.headerContainer}>
        <Toolbar className={classes.toolbar} disableGutters>
          <Link href={"https://www.well.co.uk/"} className={classes.logoLink}>
            <WellLogo className={classes.logo} />
          </Link>
          <UserProfile {...getUserProfileProps()} />
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
