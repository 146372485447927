import React from "react";
import { Typography, Alert, Divider } from "@welldigital/components";

export type RenderApprovedProps = {};

export const RenderApproved: React.FC<RenderApprovedProps> = () => {
  return (
    <>
      <Alert
        message={
          <>
            <Typography
              color={"inherit"}
              variant={"subtitle2"}
              spacingAfter={1}
            >
              Thanks, your order is confirmed
            </Typography>
            <Typography color={"inherit"} variant={"caption"}>
              Thanks for confirming that nothing has changed with your health.
              Your Direct Debit will leave your account on the agreed date. We
              will send you delivery updates once your order has been
              dispatched.
            </Typography>
          </>
        }
        type={"success"}
        spacingAfter={4}
      />
      <Divider />
    </>
  );
};

export default RenderApproved;
