import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import TagManager from "react-gtm-module";
import { PersistGate } from "redux-persist/integration/react";
import { CssBaseline } from "@material-ui/core";
import { ThemeProvider, wellColors } from "@welldigital/components";
import { ThemingProps } from "@welldigital/components/theming";
import { Optimize } from "@welldigital/ui-common/Optimize";
import { persistor } from "./store";
import ScrollToTop from "components/ScrollToTop";
import OnlineRoutes from "./routes/";
import "./styles.css";

if (process.env.REACT_APP_OPTIMIZE_ID && process.env.REACT_APP_GTAG_ID) {
  Optimize.registerOptimize(
    process.env.REACT_APP_OPTIMIZE_ID,
    process.env.REACT_APP_GTAG_ID
  );
}

if (process.env.REACT_APP_GTM_ID) {
  TagManager.initialize({
    gtmId: process.env.REACT_APP_GTM_ID,
  });
}

const onlineCustomTheming: ThemingProps = {
  // TODO: remove when the design system is finished
  props: {
    MuiLink: {
      color: "secondary",
    },
  },
  overrides: {
    MuiTypography: {
      gutterBottom: {
        marginBottom: "1rem",
      },
    },
  },
  palette: {
    primary: {
      light: wellColors.zen[50],
      main: wellColors.zen[500],
      dark: wellColors.zen[900],
    },
    secondary: {
      light: wellColors.zen[50],
      main: wellColors.zen[50],
      dark: wellColors.zen[100],
      contrastText: wellColors.zen[900],
    },
  },
};

const App = () => {
  return (
    <ThemeProvider themeName={"services"} custom={onlineCustomTheming}>
      <CssBaseline />
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <ScrollToTop />
          <OnlineRoutes />
        </Router>
      </PersistGate>
    </ThemeProvider>
  );
};
export default App;
