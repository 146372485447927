import { makeStyles, Theme } from "@material-ui/core";
import { wellColors } from "@welldigital/components";

export const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: "flex",

    "& .braintree-sheet__header": {
      display: "none",
    },

    "& .braintree-sheet": {
      border: "none",
    },

    "& .braintree-sheet__content": {
      "&.braintree-sheet__content--form": {
        padding: 0,

        "& .braintree-form__flexible-fields": {
          display: "block",
          margin: theme.spacing(2, 0, 1),

          [theme.breakpoints.up("md")]: {
            display: "flex",
          },

          "& div[data-braintree-id='expiration-date-field-group']": {
            [theme.breakpoints.up("md")]: {
              marginRight: theme.spacing(2),
            },
          },
        },

        "& .braintree-form__field-group": {
          paddingLeft: 0,

          "&.braintree-form__field-group--has-error": {
            "& .braintree-form__field": {
              "& .braintree-form__hosted-field": {
                borderColor: "#CA2A2A !important;",
              },
            },
          },

          "& .braintree-form__field": {
            "& .braintree-form__hosted-field": {
              borderColor: "rgba(0, 0, 0, 0.23)",
              borderRadius: "4px",
              height: `${theme.spacing(7)}px`,
              cursor: "text",

              "&:hover": {
                borderColor: "#212121",
              },

              "&.braintree-hosted-fields-focused": {
                transition: "border-color 0s",
                borderColor: theme.palette.primary.main,
                borderWidth: "2px",

                "&:hover": {
                  borderColor: theme.palette.primary.main,
                },
              },
            },
          },
        },
      },
    },
  },

  payButton: {
    minWidth: "auto",
    [theme.breakpoints.up("sm")]: {
      minWidth: "150px",
    },
  },

  pageContent: {
    width: "100%",
    maxWidth: "575px",
    margin: "0 auto",
  },

  title: {
    fontSize: "34px",
    color: wellColors.elixir[900],
  },

  subTitle: {
    fontSize: "18px",
    fontWeight: theme.typography.fontWeightMedium,
    color: wellColors.elixir[900],
  },

  flexContainer: {
    display: "flex",
    marginTop: theme.spacing(2),
  },

  loadingSpinnerCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  circularProgress: {
    marginRight: theme.spacing(1),
  },

  errorMessage: {
    marginTop: theme.spacing(4),
  },
}));
