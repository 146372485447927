import { makeStyles, Theme } from "@material-ui/core";
import { wellColors } from "@welldigital/components";

export const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontSize: "34px",
    color: wellColors.elixir[900],
  },

  subTitle: {
    fontSize: "18px",
    fontWeight: theme.typography.fontWeightBold,
    color: wellColors.elixir[900],
  },

  paragraph: {
    color: wellColors.elixir[400],
  },

  disclaimer: {
    color: wellColors.elixir[300],
  },

  textBold: {
    fontWeight: theme.typography.fontWeightBold,
  },

  link: {
    color: theme.palette.primary.main,
  },

  flexContainer: {
    display: "flex",
  },

  flexItemLeft: {
    flex: 1,
    marginRight: theme.spacing(3),
  },

  pageContainer: {
    display: "flex",
  },

  pageContent: {
    maxWidth: "575px",
    margin: "0 auto",
  },

  searchButton: {
    minWidth: "122px",
  },

  alert: {
    margin: theme.spacing(3, 0),
  },

  overlay: {
    position: "fixed",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: "grid",
    placeContent: "center",
    backgroundColor: "#fff",
    zIndex: 2,
  },
}));
